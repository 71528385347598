<template>
  <div>
    <b-card :title="$t('Leader Bonus Setting')">
      <b-table-simple class="mb-1">
        <b-thead head-variant="primary">
          <b-tr>
            <b-td>
              {{ $t('Class') }}
            </b-td>
            <b-td>{{ $t('Point') }}</b-td>
            <b-td>{{ $t('Amount') }}</b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr
            v-for="(item,index) in list"
            :key="index"
          >
            <b-td>{{ item.name }} </b-td>
            <b-td>
              <cleave
                id="point"
                v-model="list[index].point"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Enter point')"
              />
            </b-td>
            <b-td>
              <cleave
                id="amount"
                v-model="list[index].amount"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Enter amount')"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-button
        variant="primary"
        class="mr-2"
        @click="saveChanged"
      >
        {{ $t('Save Changes') }}
      </b-button>
      <b-button
        class="mr-2"
      >
        {{ $t('Discard') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BTr,
  BTd,
  BTbody,
  BTableSimple,
  BThead,
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Cleave from 'vue-cleave-component'

// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'

const STORE_MODULE_NAME = 'mlm'

export default {
  components: {
    BTr,
    BTd,
    BTbody,
    BTableSimple,
    BThead,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      perPageOptions,
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      list: [
        {
          id: 1,
          name: 'Bronze',
          point: 1000,
          amount: 50000,
        },
        {
          id: 2,
          name: 'Silver',
          point: 2500,
          amount: 120000,
        },
        {
          id: 3,
          name: 'Gold',
          point: 5000,
          amount: 250000,
        },
        {
          id: 4,
          name: 'Platinum',
          point: 10000,
          amount: 500000,
        },
        {
          id: 5,
          name: 'Ruby',
          point: 10000,
          amount: 1250000,
        },
        {
          id: 6,
          name: 'Emerald',
          point: 10000,
          amount: 2250000,
        },
        {
          id: 7,
          name: 'Diamond',
          point: 10000,
          amount: 5000000,
        },
      ],
    }
  },
  computed: {
    Options() {
      return [
        { name: this.$t('Percent'), value: 'percent' },
        { name: this.$t('Constant'), value: 'constant' },
      ]
    },
    leaderBonus() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.leaderBonus : []
    },
  },
  watch: {
    leaderBonus: {
      handler(newValue, oldValue) {
        if (newValue) {
          for (let index = 0; index < this.list.length; index += 1) {
            const { point, amount } = this.returnValue(this.list[index].id)
            this.list[index].point = point
            this.list[index].amount = amount
          }
        }
      },
      deep: true, // ใช้ deep เพื่อติดตามการเปลี่ยนแปลงในระดับลึก
    },
  },
  mounted() {
    if (this.leaderBonus) {
      for (let index = 0; index < this.list.length; index += 1) {
        const { point, amount } = this.returnValue(this.list[index].id)
        this.list[index].point = point
        this.list[index].amount = amount
      }
    }
  },
  methods: {
    returnValue(id) {
      const index = this.leaderBonus.findIndex(e => e.id === id)
      if (index > -1) {
        return this.leaderBonus[index]
      }
      return {
        point: 0,
        amount: 0,
      }
    },
    saveChanged() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/leaderBonus`, {
          leaderBonus: this.list.map(e => ({
            ...e,
            point: e.point.toString().replaceAll(',', ''),
            amount: e.amount.toString().replaceAll(',', ''),
          })),
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
