<template>
  <div>
    <b-tabs
      v-model="tabIndex"
      pills
    >
      <b-tab
        v-for="(item,index) in buttonList"
        :key="index"
        :title="item.name"
        :active="tabIndex ===index?true:false"
      />

    </b-tabs>
    <transition
      name="zoom-fade"
      mode="out-in"
    >
      <RetailBonus v-if="tabIndex === 0" />
      <InviteBonus v-if="tabIndex === 1" />
      <TeamBonus v-if="tabIndex === 2" />
      <Cycle v-if="tabIndex === 3" />
      <LeaderBonus v-if="tabIndex === 4" />
      <Settings v-if="tabIndex === 5" />
    </transition>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import store from '@/store'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import storeModule from '@/store/services/eCommerce/mlm'

import RetailBonus from './components/RetailBonus.vue'
import InviteBonus from './components/InviteBonus.vue'
import TeamBonus from './components/TeamBonus.vue'
import Cycle from './components/Cycle.vue'
import LeaderBonus from './components/LeaderBonus.vue'
import Settings from './components/Settings.vue'

const STORE_MODULE_NAME = 'mlm'

export default {
  components: {
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    RetailBonus,
    InviteBonus,
    TeamBonus,
    Cycle,
    LeaderBonus,
    Settings,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      // eslint-disable-next-line radix
      tabIndex: localStorage.getItem('tabIndex') ? parseInt(localStorage.getItem('tabIndex')) : 0,
      perPageOptions,
    }
  },
  computed: {
    buttonList() {
      return [{
        name: this.$t('Retail bonus'),
        value: 'RetailBonus',
      },
      {
        name: this.$t('Invite bonus'),
        value: 'InviteBonus',
      },
      {
        name: this.$t('Team bonus'),
        value: 'TeamBonus',
      },
      {
        name: this.$t('Cycle 4 week'),
        value: 'Cycle',
      },
      {
        name: this.$t('Leader bonus'),
        value: 'LeaderBonus',
      },
      {
        name: this.$t('Settings'),
        value: 'Settings',
      },
      ]
    },
  },
  watch: {
    tabIndex(newVal, oldVal) {
      localStorage.setItem('tabIndex', newVal.toString())
    },
  },
  created() {
    if (!store.hasModule(STORE_MODULE_NAME)) store.registerModule(STORE_MODULE_NAME, storeModule)
    this.get()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) store.unregisterModule(STORE_MODULE_NAME)
  },
  setup() {
    return { STORE_MODULE_NAME }
  },
  methods:{
    get() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/get`, {})
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
