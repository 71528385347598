<template>
  <div>
    <b-card :title="$t('Invite Bonus Setting')">
      <b-table-simple class="mb-1">
        <b-thead head-variant="primary">
          <b-tr>
            <b-td>
              {{ $t('Me') }}
            </b-td>
            <b-td
              v-for="(item, index) in memberLevel"
              :key="index"
            >
              {{ item.name }}
            </b-td>
          </b-tr>
        </b-thead>
        <b-tbody>
          <b-tr>
            <b-td>{{ $t('Personal') }} </b-td>
            <b-td
              v-for="(item, index) in memberLevel"
              :key="index"
            >
              <cleave
                v-if="list[index]"
                :id="`amount_${index}`"
                v-model="list[index].amount"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Enter amount')"
              />
            </b-td>
          </b-tr>
          <b-tr
            v-for="(itemI, i) in memberLevel"
            :key="i"
          >
            <b-td>{{ itemI.name }} </b-td>
            <b-td
              v-for="(itemJ, j) in memberLevel"
              :key="j"
            >
              <cleave
                v-if="list[memberLevel.length+(i * (memberLevel.length)) + (j)]"
                :id="`amount_${i}_${j}`"
                v-model="list[memberLevel.length+(i * (memberLevel.length)) + (j)].amount"
                class="form-control"
                :raw="false"
                :options="optionCleave.number"
                :placeholder="$t('Enter amount')"
              />
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-button
        variant="primary"
        class="mr-2"
        @click="saveChanged"
      >
        {{ $t('Save Changes') }}
      </b-button>
      <b-button
        class="mr-2"
      >
        {{ $t('Discard') }}
      </b-button>
    </b-card>
  </div>
</template>

<script>
import {
  BTr,
  BTd,
  BTbody,
  BTableSimple,
  BThead,
  BTabs,
  BTab,
  BCard,
  BRow,
  BCol,
  BImg,
  BAvatar,
  BFormInput,
  BButton,
  BTable,
  BPagination,
  BDropdown,
  BOverlay,
  BCardText,
  BDropdownItem,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import perPageOptions from '@/perPageOptions'
// import { useToast } from 'vue-toastification/composition'
import storeModuleMemberLevel from '@/store/services/eCommerce/memberLevel'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import { ref } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import Cleave from 'vue-cleave-component'

const STORE_MODULE_NAME_MEMBER_LEVEL = 'memberLevel'
const STORE_MODULE_NAME = 'mlm'

export default {
  components: {
    BTr,
    BTd,
    BTbody,
    BTableSimple,
    BThead,
    BTabs,
    BTab,
    BCard,
    BRow,
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BImg,
    // eslint-disable-next-line vue/no-unused-components
    BAvatar,
    BFormInput,
    BButton,
    BTable,
    BPagination,
    BDropdown,
    BDropdownItem,
    BOverlay,
    BCardText,
    Cleave,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      avatarText,
      perPageOptions,
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      list: [],

    }
  },
  computed: {
    memberLevel() {
      return store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData != null
        ? store.state[STORE_MODULE_NAME_MEMBER_LEVEL].respData.data.map(e => ({
          _id: e._id,
          name: `${this.showFromCurrentLanguage(e.name)}`,
        }))
        : []
    },
    inviteBonus() {
      return store.state[STORE_MODULE_NAME].respData != null ? store.state[STORE_MODULE_NAME].respData.inviteBonus : []
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) store.registerModule(STORE_MODULE_NAME_MEMBER_LEVEL, storeModuleMemberLevel)
    this.getMemberLevel()
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME_MEMBER_LEVEL)) store.unregisterModule(STORE_MODULE_NAME_MEMBER_LEVEL)
  },
  setup() {
    return { STORE_MODULE_NAME_MEMBER_LEVEL }
  },
  methods: {
    saveChanged() {
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME}/inviteBonus`, {
          inviteBonus: this.list.map(e => ({
            ...e,
            amount: e.amount.toString().replaceAll(',', ''),
          })),
        })
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Success'),
              icon: 'SuccessIcon',
              variant: 'success',
              text: this.$t(result.data.message),
            },
          })
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    getMemberLevel() {
      const obj = {
        currentPage: 0,
        pageSize: 0,
        searchQuery: '',
      }
      this.show = true
      store
        .dispatch(`${STORE_MODULE_NAME_MEMBER_LEVEL}/get`, obj)
        // eslint-disable-next-line no-unused-vars
        .then(result => {
          this.show = false
          const dataMemberLevel = result.data.data
          const format = []
          for (let index = 0; index < dataMemberLevel.length; index += 1) {
            const amount = this.returnAmount(null, dataMemberLevel[index]._id)
            format.push({
              recommender: null,
              invitee: dataMemberLevel[index]._id,
              amount,
            })
          }

          dataMemberLevel.forEach(recommender => {
            dataMemberLevel.forEach(invitee => {
              const amount = this.returnAmount(recommender._id, invitee._id)

              format.push({
                recommender: recommender._id,
                invitee: invitee._id,
                amount,
              })
            })
          })
          this.list = format
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.show = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    returnAmount(recommender, invitee) {
      const index = this.inviteBonus.findIndex(e => e.recommender === recommender && e.invitee === invitee)
      if (index > -1) {
        return this.inviteBonus[index].amount
      }
      return 0
    },
    showFromCurrentLanguage(data) {
      const indexLang = data.findIndex(e => e.lang === this.$i18n.locale)
      if (indexLang > -1) {
        return data[indexLang].value
      }
      return ''
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.v-select-custom {
  display: inline-block;
  width: 50;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.b-col-custom {
  margin-top: 0.5rem;
}
</style>
